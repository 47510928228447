import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`Today is our Granite Games Fall Throwdown!  Come out and cheer on
the teams competing and check out our Vendors!  First heat starts at
9:00am.  Reminder: There is a 5k run downtown today so you may have to
park off Market or Washington street and walk over between the hours of
8-10:30am.`}</strong></p>
    <p><em parentName="p">{`(The gym will be closed today for The Granite Games Fall Throwdown)`}</em></p>
    <p><strong parentName="p">{`*`}{`Bluegrass Bodyfat Testing will be set up to do body fat tests for
just \\$30 (\\$25 for GG competitors)!  This is a \\$15-\\$20 savings off
their normal prices so take advantage of this deal!  You will get wet so
bring swimwear or workout clothes and a
towel. `}<a parentName="strong" {...{
          "href": "http://www.bluegrassbftest.com"
        }}>{`http://www.bluegrassbftest.com`}</a>{`. `}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      